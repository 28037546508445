import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import App from "./App";
import { auth } from "./firebase";
import Login from "./Login";
import Signup from "./Signup";

class Main extends React.Component {
  state = { 
    currentUser: null,
    isSignupInProgress: false
  };

  componentDidMount() {
    // Check if user was on the signup page
    const savedSignupState = sessionStorage.getItem('signupInProgress');
    if (savedSignupState === 'true') {
      this.setState({ isSignupInProgress: true });
    }
    
    auth.onAuthStateChanged((user) => {
      // Only update state if not in signup process
      if (!this.state.isSignupInProgress) {
        this.setState({ currentUser: user });
      } else if (!user) {
        // If user was signed out after signup, clear the signup flag
        this.setState({ isSignupInProgress: false });
        sessionStorage.removeItem('signupInProgress');
      }
    });
  }

  setSignupInProgress = (inProgress) => {
    this.setState({ isSignupInProgress: inProgress });
    // Save to session storage for persistence
    sessionStorage.setItem('signupInProgress', inProgress ? 'true' : 'false');
  };

  render() {
    const { currentUser, isSignupInProgress } = this.state;

    return (
      <Router>
        <Routes>
          <Route
            path="/login"
            element={
              currentUser && !isSignupInProgress 
                ? <Navigate to="/" /> 
                : <Login />
            }
          />
          <Route
            path="/signup"
            element={
              <Signup setSignupInProgress={this.setSignupInProgress} />
            }
          />
          <Route
            path="/help"
            element={currentUser ? <Navigate to="/help" /> : <Login />}
          />
          <Route
            path="/"
            element={
              currentUser && !isSignupInProgress 
                ? <App /> 
                : <Navigate to="/login" />
            }
          />
        </Routes>
      </Router>
    );
  }
}

export default Main;
