import React, { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { auth } from "./firebase";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";

const myTheme = createTheme({
  typography: {
    fontFamily: "sans-serif",
  },
  palette: {
    primary: {
      main: "#2e6c7b",
    },
  },
});

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [resetEmail, setResetEmail] = useState("");
  const [resetMessage, setResetMessage] = useState(null);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [unverifiedEmail, setUnverifiedEmail] = useState("");
  
  // Check local storage for verification flag on component mount
  useEffect(() => {
    const storedEmail = localStorage.getItem("unverifiedEmail");
    if (storedEmail) {
      setUnverifiedEmail(storedEmail);
      // Pre-fill the email field
      setEmail(storedEmail);
    }
  }, []);
  
  const clearVerificationFlag = () => {
    localStorage.removeItem("unverifiedEmail");
    setUnverifiedEmail("");
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      if (!user.emailVerified) {
        console.log("User is not verified");
        
        // Store unverified email in local storage
        localStorage.setItem("unverifiedEmail", email);
        setUnverifiedEmail(email);
        
        // Sign out
        await auth.signOut();
        
        // Show verification required message
        setError("Please verify your email before logging in. Check your inbox for the verification link.");
        
        return;
      }
      // If email is verified, the user will be automatically redirected by the router
    } catch (error) {
      let errorMessage = "Failed to log in";
      switch (error.code) {
        case 'auth/user-not-found':
          errorMessage = "No account found with this email";
          break;
        case 'auth/wrong-password':
          errorMessage = "Invalid password";
          break;
        case 'auth/invalid-email':
          errorMessage = "Invalid email address";
          break;
        case 'auth/user-disabled':
          errorMessage = "This account has been disabled";
          break;
        default:
          errorMessage = error.message;
      }
      setError(errorMessage);
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetMessage("Password reset email sent! Check your inbox.");
    } catch (error) {
      let errorMessage = "Failed to send reset email";
      switch (error.code) {
        case 'auth/user-not-found':
          errorMessage = "No account found with this email";
          break;
        case 'auth/invalid-email':
          errorMessage = "Invalid email address";
          break;
        default:
          errorMessage = error.message;
      }
      setResetMessage(errorMessage);
    }
  };

  return (
    <ThemeProvider theme={myTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        
        {/* Verification Message Banner - Always visible if unverified */}
        {unverifiedEmail && (
          <Paper 
            elevation={3} 
            sx={{ 
              padding: 2, 
              marginTop: 2, 
              marginBottom: 2,
              backgroundColor: 'white',
              border: '1px solid #e0e0e0'
            }}
          >
            <Typography variant="h6" color="error" gutterBottom>
              Email Verification Required
            </Typography>
            <Typography variant="body1" gutterBottom>
              Your email <strong>{unverifiedEmail}</strong> requires verification before you can log in.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Please check your inbox (and spam folder) for the verification email and click the verification link.
            </Typography>
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Button 
                variant="outlined" 
                color="primary" 
                size="small"
                onClick={clearVerificationFlag}
              >
                Dismiss
              </Button>
            </Box>
          </Paper>
        )}
        
        <Box
          sx={{
            marginTop: unverifiedEmail ? 2 : 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={process.env.PUBLIC_URL + "/logo-chessintel-2.png"}
            alt="Logo"
            style={{ maxWidth: "50vw" }}
          />
          <Box
            component="form"
            onSubmit={handleLogin}
            noValidate
            sx={{ mt: 1, width: '100%' }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && (
              <Alert 
                severity="error" 
                sx={{ 
                  mt: 2,
                  mb: 2,
                  width: '100%',
                }}
              >
                {error}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  href="#"
                  variant="body2"
                  onClick={() => setShowResetPassword(true)}
                >
                  Reset/Forgot password
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
          
          {showResetPassword && (
            <Box
              component="form"
              onSubmit={handlePasswordReset}
              noValidate
              sx={{ mt: 2, width: '100%' }}
            >
              <Typography component="h2" variant="h6">
                Reset Password
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="resetEmail"
                label="Email Address"
                name="resetEmail"
                autoComplete="email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Send Reset Email
              </Button>
              {resetMessage && (
                <Alert 
                  severity={resetMessage.includes("sent") ? "success" : "error"}
                  sx={{ mt: 2 }}
                >
                  {resetMessage}
                </Alert>
              )}
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
