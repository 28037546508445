import React, { useState, useEffect } from "react";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { auth } from "./firebase";
import Alert from "@mui/material/Alert";

const myTheme = createTheme({
  typography: {
    fontFamily: "sans-serif",
  },
  palette: {
    primary: {
      main: "#2e6c7b",
    },
  },
});

const Signup = ({ setSignupInProgress }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [verificationSent, setVerificationSent] = useState(false);
  
  // Set signup in progress when component mounts
  useEffect(() => {
    if (setSignupInProgress) {
      setSignupInProgress(true);
    }
    // Clean up when component unmounts
    return () => {
      if (setSignupInProgress) {
        setSignupInProgress(false);
      }
    };
  }, [setSignupInProgress]);

  const handleSignup = async (e) => {
    e.preventDefault();
    setError(null);
    
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (password.length < 6) {
      setError("Password should be at least 6 characters long");
      return;
    }

    try {
      // First set verification sent flag to true
      // This prevents any navigation due to auth state changes
      setVerificationSent(true);
      
      // Store email in localStorage to show verification banner on login page
      localStorage.setItem("unverifiedEmail", email);
      
      // Create user account
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      // Send verification email
      await sendEmailVerification(user);
      
      // Sign out immediately to prevent any redirect to main app
      await auth.signOut();
      
    } catch (error) {
      // Reset verification flag if there's an error
      setVerificationSent(false);
      
      let errorMessage = "An error occurred during signup";
      switch (error.code) {
        case 'auth/email-already-in-use':
          errorMessage = "This email is already registered";
          break;
        case 'auth/invalid-email':
          errorMessage = "Invalid email address";
          break;
        case 'auth/operation-not-allowed':
          errorMessage = "Email/password accounts are not enabled. Please contact support.";
          break;
        case 'auth/weak-password':
          errorMessage = "Password is too weak";
          break;
        default:
          errorMessage = error.message;
      }
      setError(errorMessage);
    }
  };

  if (verificationSent) {
    return (
      <ThemeProvider theme={myTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center"
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/logo-chessintel-2.png"}
              alt="Logo"
              style={{ maxWidth: "50vw" }}
            />
            <Alert severity="success" sx={{ mt: 3, mb: 2, width: '100%' }}>
              Account created successfully!
            </Alert>
            <Typography variant="h6" sx={{ mt: 2, fontWeight: 'medium' }}>
              Verify Your Email Address
            </Typography>
            <Box sx={{ mt: 2, mb: 3, p: 3, border: '1px solid #e0e0e0', borderRadius: 1, backgroundColor: 'white', width: '100%', boxShadow: 1 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                A verification link has been sent to:
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {email}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                You must verify your email before you can log in.
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ mb: 3 }}>
              Please check your inbox (and spam folder) and click the verification link to activate your account.
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              After verifying your email, you can use the same email and password to log in.
            </Typography>
            <Grid container justifyContent="center" sx={{ mt: 2 }}>
              <Grid item>
                <Link href="/login" variant="body2">
                  Already verified? Go to Login
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={myTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={process.env.PUBLIC_URL + "/logo-chessintel-2.png"}
            alt="Logo"
            style={{ maxWidth: "50vw" }}
          />
          <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
            Sign Up
          </Typography>
          <Box
            component="form"
            onSubmit={handleSignup}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              helperText="Password must be at least 6 characters long"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Signup; 